.home-slider {
	position: relative;
	height: 500px;
	overflow: hidden;
	.center-content {
		height: 100%;
	}
	.triangle {
		position: absolute;
		fill: rgba(0,0,0,0.1);
		pointer-events: none;
	}
	.t1 {
		left: 0;
		bottom: -15vh;
		width: 500px;
		height: 500px;
		transform: scale(3,2);
	}
	.t2 {
		width: 500px;
		height: 500px;
		bottom: 0;
		left: 54vw;
		transform: scale(5, 2) rotate(-90deg);
	}
	.slide {
		position: absolute;
		width: 100%;
		height: 100%;
		background: $color__primary;
	}
	.slide-content {
		color: #fff;
		width: 400px;
		padding-top: 45px;
	}
	.slide-title {
		text-transform: uppercase;
		font-weight: normal;
		font-size: 3rem;
	}
	.slide-image {
		position: absolute;
		height: 100%;
		width: auto;
		top: 0;
		right: -28vw;
		z-index: 100;
	}
}
.slidesjs-container {
	height: 100% !important;
	.slide-image {
		height: 100%;
	}
}
.slidesjs-control {
	height: 100% !important;
}
.slidesjs-pagination {
	position: absolute;
	bottom: 0;
	width: 100%;
	text-align: center;
	z-index: 1100;
}
.slidesjs-pagination-item {
	display: inline-block;
	margin: 0 7px;
	a {
		overflow: hidden;
		text-indent: -99em;
		display: inline-block;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		border: 3px solid #fff;
		&.active {
			background: #fff;
		}
	}
}
