@import 'variables';
@import 'normalize';
@import 'site/layout';
@import 'sections/sobre-nosotros';
@import 'sections/servicios';
@import 'sections/productos';
@import 'sections/contacto';
@import 'components/header';
@import 'components/slider';
@import 'components/footer';

@media screen and (max-width: 1024px) { 	
	.center-content {
		width: 100%;
		padding: 0 20px;
	}
	.site-logo img {
		width: 200px;
	}
	.menu-item {
		padding: 0 7px;
	}
	.home-slider {
		height: 650px;
	}
	.home-slider .slide-image {
		position: relative;
		height: auto;
		width: 100%;
		right: 0;
	}
	.home-slider .slide-content {
		width: 100%;
		padding-top: 25px;
	}
	.home-slider .slide-title {
		margin: 0 0 10px;
		font-size: 2.2rem;
	}
	.slide-title br {
		display: none;
		margin-left: 25px;
	}
	.mmv-item {
		flex: 1;
		padding: 45px 10px;
		text-align: center;
		ul {
			list-style: none;
			padding: 0;
		}
	}
	.productos-carousel {
		padding: 45px;
	}
	.carousel-nav.next {
		right: 40px;
	}
	.carousel-nav.prev {
		left: 40px;
	}
	.contact-column {
		width: 100%;
		padding: 0;
	}
	.info-empresa {
		white-space: normal;
		padding-bottom: 250px;
	}
	.info-empresa .foto-sn {
		width: 33.7%;
		min-height: auto;
	}
	.info-empresa .sobre-nosotros-content {
		width: 100%;
		left: 0;
		top: 370px;
	}
}

@media screen and (max-width: 600px) { 
	html {
		font-size: 90%;
	}
	.center-content {
		width: 100%;
		padding: 15px;
	}
	.main-menu {
		display: none;
		position: absolute;
		top: 100%;
		left: 0;
		margin: 0;
		width: 100%;
		opacity: 0;
		li {
			background: rgba(#000, 0.9);
			display: block;
			width: 100%;
			a {
				color: #fff;
			}
		}
		&.active {
			opacity: 1;
			display: block;
		}
	}
	.select-language {
		ul {
			padding-left: 15px;
			margin-top: 7px;
		}
		li {
			display: block;
			a {
				display: block;
				padding: 0 7px 7px;
			}
		}
	}
	.toggle-menu {
		display: flex;
	}
	.main-content {
		margin-top: 100px;
	}
	.site-branding {
		text-align: center;
	}
	.site-logo {
		margin: 0;
	}
	.site-logo img {
		width: 200px;
	}
	.home-slider {
		height: 500px;
	}
	.sobre-nosotros {
		margin: 0;
	}
	.info-empresa .sobre-nosotros-text {
		columns: 1;
	}
	.info-empresa {
		white-space: normal;
	}
	.info-empresa .foto-sn {
		width: 50%;
		min-width: auto;
		min-height: auto;
	}
	.info-empresa .sobre-nosotros-content {
		width: 100%;
		padding: 20px;
		position: relative;
	}
	.sobre-nosotros .section-title {
		margin: 25px 0;
	}
	.mmv {
		display: block;
	}
	.mmv-item {
		padding: 0 !important;
	}
	.banner {
		font-size: 2.2rem;
		padding: 25px 20px;
	}
	.servicios-list {
		display: block;
	}
	.servicio-item {
		width: 50%;
		float: left;
	}
	.servicio-icono {
		width: 110px;
		height: 110px;
	}
	.servicio-label {
		padding: 10px 15px;
	}
	.servicios-detalle {
		padding: 25px 0;
	}
	.servicios-detalle .center-content {
		display: block;
	}
	.contact-column {
		width: 100%;
		padding: 0;
		margin-bottom: 25px;
	}
	.carousel-nav {
		width: 40px;
		height: 40px;
		border-width: 8px 8px 0 0;
	    bottom: 90px;
	}
	.carousel-nav.prev {
		left: 30px;
	}
	.carousel-nav.next {
		right: 30px;
	}
	.productos-carousel {
		height: 200px;
		padding: 20px 75px;
	}
	.productos-carousel .wrap {
		width: 100%;
		height: 100%;
	}
	.productos-carousel .wrap ul {
		display: flex;
		flex-wrap: nowrap;
		justify-content: flex-start;
		width: 2000px;
	}
	.carousel-item {
		width: 200px;
	}
	.carousel-img {
		height: 150px;
		margin: 0;
	}
	.info-empresa .sobre-nosotros-content {
		left: 0;
		top: 0;
	}
	.info-empresa {
		white-space: normal;
		padding-bottom: 0;
	}
}
