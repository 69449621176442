$color__primary: #0071B9;
$color__lighter: lighten($color__primary, 10);
$color__dark: #333;
$color__copy: #4d4d4d;  

.clearfix {
	&:after {
		clear: both;
		content: '';
		display: table;
		table-layout: fixed;
	}
}