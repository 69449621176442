.contacto {
	background: $color__dark;
	float: left;
	width: 100%;
	color: #bbb;
	padding: 45px 0;
	.section-title {
		text-align: center;
	}
	.section-title:after {
		left: 50%;
		transform: translateX(-50%);
		margin-bottom: 45px;
	}
}
input[type='text'],
input[type='email'],
input[type='phone'],
input[type='search'],
textarea {
	background: #4d4d4d;
	color: #b0b0b0;
	padding: 15px;
	border: 0;
	border-radius: 0;
	width: 100%;
	margin-bottom: 25px;
}

input {
	background: $color__primary;
	border: 0;
	padding: 10px 25px;
	color: #fff;
}
.contact-info {
	margin: 35px 0;
}
.contact-column {
	width: 50%;
	float: left;
	padding-right: 25px;
}