.productos {
	text-align: center;
	position: relative;
	float: left;
	width: 100%;
	.section-title:after {
		left: 50%;
		transform: translateX(-50%);
		margin-bottom: 45px;
	}
}
.productos-carousel {
	background: #ededed;
	height: 300px;
	padding: 45px 75px;
	.wrap {
		width: 80%;
		overflow: hidden;
		margin: 0 auto;
	}
	ul {
		margin: 0;
		padding: 0;
		white-space: nowrap;
		transition: transform .5s;
	}
}
.carousel-item {
	display: inline-block;
}
.carousel-img {
	margin: 0 25px;
}
.carousel-nav {
	display: block;
	width: 60px;
	height: 60px;
	border: solid #777;
	border-width: 12px 12px 0 0;
	position: absolute;
	bottom: 120px;
	&.prev {
		transform: rotate(-135deg);
		left: 90px;
	}
	&.next {
		transform: rotate(45deg);
		right: 90px;
	}
	&.inactive {
		border-color: #ccc;
	}
}