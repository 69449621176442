.site-footer {
	background: #1a1a1a;
	text-align: center;
	float: left;
	width: 100%;
}
.site-info {
	color: #888;
	padding: 30px 15px;
	font-size: 0.8rem; 
}