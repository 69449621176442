.servicios {
	background: #efefef;
	float: left;
	width: 100%;
	position: relative;
	overflow: hidden;
	padding: 45px 0;
	.triangle {
		position: absolute;
		fill: rgba(0,0,0,0.05);
		pointer-events: none;
	}
	.t1 {
		left: 0;
		bottom: -15vh;
		width: 500px;
		height: 500px;
		transform: scale(5,2);
	}
	.t2 {
		width: 300px;
		height: 300px;
		bottom: 0;
		right: 0;
		transform: scale(8, 1) rotate(-90deg);
	}
}
.servicios-content {
	text-align: center;
	padding: 0 10vw 40px;
	.section-title:after {
		left: 50%;
		transform: translateX(-50%);
	}
}
.servicios-list {
	display: flex;
	position: relative;
	z-index: 1000;
}
.servicio-item {
	width: 25%;
	text-align: center;
}
.servicio-icono {
	background: $color__primary;
	padding: 20px;
	display: inline-block;
	height: 150px;
	width: 150px;
	border-radius: 50%;
}
.servicio-label {
	display: block;
	font-weight: bold;
	padding: 10px 50px;
}
.servicios-detalle {
	padding: 65px 0;
	float: left;
	width: 100%;
	.center-content {
		display: flex;
	}
	h3 {
		color: $color__primary;
		font-size: 1.8rem;
		margin-bottom: 10px;
	}
	ul {
		margin-top: 25px;
	}
	li {
		margin-bottom: 10px;
	}
}
.servicios-column {
	padding-right: 35px;
}