.center-content {
	width: 1024px;
	margin: 0 auto;
	position: relative;
	@extend .clearfix;
}
.main-content {
	margin-top: 130px;
}
.section-title {
	font-size: 2.5rem;
	position: relative;
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 110%;
		background: $color__primary;
		height: 8px;
		width: 100px;
	}
}