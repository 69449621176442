.sobre-nosotros {
	background: $color__dark;
	margin: 10vh 0;
	.center-content {
		height: 100%;
		position: static;
	}
	.section-title {
		margin: 25px 0 40px;
		width: 100%;
	}
}
.info-empresa {
	position: relative;
	overflow: hidden;
	white-space: nowrap;
	text-align: center;
	@extend .clearfix;
	.foto-sn {
		height: auto;
		min-height: 500px;
		display: inline-block;
		width: auto;
		min-width: 250px;
		margin-left: -6px;
	}
	.sobre-nosotros-content {
		white-space: normal;
		padding: 0 45px;
		width: 1024px;
		height: 99%;
		position: absolute;
		background: rgba(#fff,0.9);
		text-align: left;
	}
	.sobre-nosotros-text {
		columns: 2;
	}
	p {
		margin-top: 0;
	}
}
.mmv {
	display: flex;
	color: #fff;
}
.mmv-item {
	padding: 45px 20px;
	flex: 2;
	&.valores {
		flex: 1
	}
}
.mmv-title {
	color: $color__lighter;
	font-size: 2rem;
	margin-bottom: 10px;
}
.banner {
	background: url('../img/banner.jpg') no-repeat center center;
	background-size: cover;
	text-transform: uppercase;
	text-align: center;
	font-family: 'Fjalla One', sans-serif;
	padding: 45px 0;
	font-size: 3rem;
	color: #fff;
}