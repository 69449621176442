.site-header {
	@extend .clearfix;
	box-shadow: 0 4px 10px rgba(#000, 0.1);
	position: fixed;
		top: 0;
		left: 0;
	width: 100%;
	z-index: 2000;
	background: rgba(#fff, 0.95);
}
.site-logo {
	float: left;
	img {
		width: 240px;
		height: auto;
	}
}
.select-language {
	float: right;
	ul {
		list-style: none;
	}
	li {
		display: inline-block;
		a {
			display: block;
			padding: 28px 7px;
		}
	}
}
.main-menu {
	float: right;
	list-style: none;
	margin: 0;
	padding: 0;
	font-family: 'Fjalla One', sans-serif;
	margin-top: 30px;
	transition: opacity 1s;
}
.menu-item {
	display: inline-block;
	text-transform: uppercase;
	padding: 0 15px;
	a {
		display: block;
		padding: 15px 0;
		color: $color__copy;
		transition: border-color 0.5s;
		border-bottom: 6px solid transparent;
		&:hover {
			color: $color__primary;
		}
	}
	a.current {
		border-bottom-color: $color__primary;
	}
}
.toggle-menu {
	background: $color__primary;
	border: 0;
	height: 40px;
	width: 40px;
	display: none;
	float: right;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	margin-top: 10px;
}
.icon-menu {
	height: 2px;
	display: inline-block;
	width: 20px;
	position: relative;
	background: #fff;
	&:before, &:after {
		content: '';
		background: #fff;
		display: block;
		height: 2px;
		width: 100%;
		position: absolute;
	}
	&:before {
		top: -6px;
	}
	&:after {
		bottom: -6px;
	}
}